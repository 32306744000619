export default defineNuxtPlugin((nuxtAPP) =>{

    // Cria uma instância de $fetch com a base URL do Django
    const api = $fetch.create({
        baseURL: `${useRuntimeConfig().public.djangoHost}`,
        retry: 0,
        // Função chamada antes de fazer a requisição
        // Nela, verifica se o usuário está autenticado e
        // adiciona o token de autenticação no cabeçalho da requisição
        onRequest({request, options, error}) {
            if(useAuthStore().token){
                const headers = {'Authorization': `Token ${useAuthStore().token}`}
                options.headers = Object.assign(headers, options.headers)
            }
        },
        // Função chamada após o erro de resposta da requisição
        async onResponseError({response}){
            // Se o status da resposta é 401, 
            // limpa o token de autenticação 
            // e redireciona para a tela de login
            if (response.status === 401 && useAuthStore().token) {
                // Exibe uma notificação
                useToast().add({ 
                    title: 'Sessão expirada!',
                    description: 'Por favor, realize o login novamente.', 
                    color: 'red',
                    icon:'i-heroicons-information-circle',
                    timeout:5000,
                    ui:{
                      ring: 'ring-0 ring-red-500 dark:ring-gray-800',
                      background:'bg-red-50',
                      title: 'text-md font-medium text-gray-900 dark:text-white',
                      rounded: 'rounded',
                      default:{
                        closeButton:{
                            color: 'red',
                        }
                      }
                    }
                })

                // Limpa os dados do usuário e redireciona para a tela de login
                useAuthStore().reset()
                navigateTo('/login')
            }
            else if(response.status === 503){
                useErrorToast('Seu dispositivo está desconectado.')
                useWppAuthStore().disconnect()
            }
            else if(response.status === 408){
                useErrorToast('Por favor, tente novamente.')
            }
        }
    })

    return {
        provide: {
            api
        }
    }

})
