import { useAuthStore } from "../stores/authStore"
import { useWppAuthStore } from "../stores/wppAuthStore"
import { useWppDisparoStore } from "../stores/wppDisparoStore"

export default defineNuxtPlugin((nuxtApp) => {
  let socket = null
  let auth = useAuthStore()
  let wppAuth = useWppAuthStore()
  let wppDisparoStore = useWppDisparoStore()

  const connectWebSocket = () => {
    const host = useRuntimeConfig().public.djangoHost
    const protocol = host.includes("https") ? "wss" : "ws"

    socket = new WebSocket(`${protocol}://${host.replace('https://', '').replace('http://', '')}/ws/connect/?token=${auth.token}`)

    socket.onopen = () => {
    }

    socket.onmessage = (event) => {
      event = JSON.parse(event.data)
      
      switch (event.message) {
        case 'qr_code_generated':
          wppAuth.qrCode = event.value
          wppAuth.status = 'qr_code_generated'
          break

        case 'authentication_ready':
          wppAuth.status = 'authenticated'
          console.log(event.value)
          if (event.value && Number(event.value)) {
            wppAuth.phoneConnected = event.value
          }
          wppAuth.qrCode = null
          break

        case 'max_retries_exceeded':
          wppAuth.qrCode = null
          wppAuth.status = 'error'
          wppAuth.error = 'Tempo expirou'
          break

        case 'send_update':
          wppDisparoStore.setDisparo(event.value)
          break
      }
    
    }

    socket.onclose = (event) => {
      wppAuth.qrCode = null
      if(auth.isLoggedIn){
      // console.log('WebSocket connection closed. Reconnecting...')
      setTimeout(connectWebSocket, 1000) // Tentar reconectar após 1 segundo
    }
    }

    socket.onerror = (error) => {
      console.error('WebSocket error: ', error)
    }
  }

  const disconnectWebSocket = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close(1000, 'Usuário desconectado'); // Código 1000 indica desconexão normal
      // console.log('WebSocket disconnected');
    } else {
      // console.log('Nenhuma conexão WebSocket ativa');
    }
  };

  // Conectar WebSocket ao carregar a aplicação
  connectWebSocket()

  // Injetar o WebSocket no nuxtApp, acessível como $socket
  nuxtApp.provide('socket', socket)
  nuxtApp.provide('disconnectWebSocket', disconnectWebSocket);
  nuxtApp.provide('connectWebSocket', connectWebSocket);
})