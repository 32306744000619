export const useErrorToast = (titulo:string,descricao:string = '') => {
    useToast().add({     
        title: titulo,
        description: descricao, 
        color: 'red',
        icon:'i-heroicons-information-circle',
        timeout:5000,
        ui:{
            ring: 'ring-0 ring-red-500 dark:ring-gray-800',
            background:'bg-red-50',
            title: 'text-md font-medium text-gray-900 dark:text-white',
            rounded: 'rounded',
            default:{
                closeButton:{
                    color: 'red',
                }
            }
        }
    })
}