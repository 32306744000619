import { defineStore } from 'pinia';


export const useWppAuthStore = defineStore({
    id: 'wppAuth',
    state: () => {
        return {
            status: '',
            qrCode: null,
            error: null,
            phoneConnected: null,
        };
    },
    getters: {
        conectado:(state) => state.status === 'authenticated',
        carregando:(state) => state.status === 'authentication_started' || state.status === 'disconnection_started',

    },
    
    actions: {

        disconnect() {
            this.status = ''
            this.qrCode = null
            this.phoneConnected = null
        }

    },
    
    persist: true,

});
