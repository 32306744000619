import { default as indexxeJdauUshKMeta } from "/webapps/qa_md1sender/frontend/pages/agendamentos/[id]/index.vue?macro=true";
import { default as indexi69T801ar6Meta } from "/webapps/qa_md1sender/frontend/pages/agendamentos/index.vue?macro=true";
import { default as indexNnMvFExLEHMeta } from "/webapps/qa_md1sender/frontend/pages/ajuda/index.vue?macro=true";
import { default as alterar_45senhasIOQsLDS9RMeta } from "/webapps/qa_md1sender/frontend/pages/alterar-senha.vue?macro=true";
import { default as _91id_93pPDiEEsMrRMeta } from "/webapps/qa_md1sender/frontend/pages/campanha/[id].vue?macro=true";
import { default as indexacAJs6cLoQMeta } from "/webapps/qa_md1sender/frontend/pages/campanhas/index.vue?macro=true";
import { default as indexISDyfOaivBMeta } from "/webapps/qa_md1sender/frontend/pages/coletar/index.vue?macro=true";
import { default as indexkfr3tgjPTGMeta } from "/webapps/qa_md1sender/frontend/pages/disparo/index.vue?macro=true";
import { default as index5dQCY3rvcfMeta } from "/webapps/qa_md1sender/frontend/pages/disparos/index.vue?macro=true";
import { default as esqueci_45minha_45senha0Chy9e6ZHFMeta } from "/webapps/qa_md1sender/frontend/pages/esqueci-minha-senha.vue?macro=true";
import { default as indexLwK5aJNTtZMeta } from "/webapps/qa_md1sender/frontend/pages/index.vue?macro=true";
import { default as login1bTCdJY3PiMeta } from "/webapps/qa_md1sender/frontend/pages/login.vue?macro=true";
import { default as indexe8zHd2jHxiMeta } from "/webapps/qa_md1sender/frontend/pages/preferencias/index.vue?macro=true";
import { default as _91token_93HQGkMXoYfZMeta } from "/webapps/qa_md1sender/frontend/pages/resetar-senha/[uid]/[token].vue?macro=true";
export default [
  {
    name: indexxeJdauUshKMeta?.name ?? "agendamentos-id",
    path: indexxeJdauUshKMeta?.path ?? "/agendamentos/:id()",
    meta: indexxeJdauUshKMeta || {},
    alias: indexxeJdauUshKMeta?.alias || [],
    redirect: indexxeJdauUshKMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/agendamentos/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexi69T801ar6Meta?.name ?? "agendamentos",
    path: indexi69T801ar6Meta?.path ?? "/agendamentos",
    meta: indexi69T801ar6Meta || {},
    alias: indexi69T801ar6Meta?.alias || [],
    redirect: indexi69T801ar6Meta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/agendamentos/index.vue").then(m => m.default || m)
  },
  {
    name: indexNnMvFExLEHMeta?.name ?? "ajuda",
    path: indexNnMvFExLEHMeta?.path ?? "/ajuda",
    meta: indexNnMvFExLEHMeta || {},
    alias: indexNnMvFExLEHMeta?.alias || [],
    redirect: indexNnMvFExLEHMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: alterar_45senhasIOQsLDS9RMeta?.name ?? "alterar-senha",
    path: alterar_45senhasIOQsLDS9RMeta?.path ?? "/alterar-senha",
    meta: alterar_45senhasIOQsLDS9RMeta || {},
    alias: alterar_45senhasIOQsLDS9RMeta?.alias || [],
    redirect: alterar_45senhasIOQsLDS9RMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/alterar-senha.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pPDiEEsMrRMeta?.name ?? "campanha-id",
    path: _91id_93pPDiEEsMrRMeta?.path ?? "/campanha/:id()",
    meta: _91id_93pPDiEEsMrRMeta || {},
    alias: _91id_93pPDiEEsMrRMeta?.alias || [],
    redirect: _91id_93pPDiEEsMrRMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/campanha/[id].vue").then(m => m.default || m)
  },
  {
    name: indexacAJs6cLoQMeta?.name ?? "campanhas",
    path: indexacAJs6cLoQMeta?.path ?? "/campanhas",
    meta: indexacAJs6cLoQMeta || {},
    alias: indexacAJs6cLoQMeta?.alias || [],
    redirect: indexacAJs6cLoQMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/campanhas/index.vue").then(m => m.default || m)
  },
  {
    name: indexISDyfOaivBMeta?.name ?? "coletar",
    path: indexISDyfOaivBMeta?.path ?? "/coletar",
    meta: indexISDyfOaivBMeta || {},
    alias: indexISDyfOaivBMeta?.alias || [],
    redirect: indexISDyfOaivBMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/coletar/index.vue").then(m => m.default || m)
  },
  {
    name: indexkfr3tgjPTGMeta?.name ?? "disparo",
    path: indexkfr3tgjPTGMeta?.path ?? "/disparo",
    meta: indexkfr3tgjPTGMeta || {},
    alias: indexkfr3tgjPTGMeta?.alias || [],
    redirect: indexkfr3tgjPTGMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/disparo/index.vue").then(m => m.default || m)
  },
  {
    name: index5dQCY3rvcfMeta?.name ?? "disparos",
    path: index5dQCY3rvcfMeta?.path ?? "/disparos",
    meta: index5dQCY3rvcfMeta || {},
    alias: index5dQCY3rvcfMeta?.alias || [],
    redirect: index5dQCY3rvcfMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/disparos/index.vue").then(m => m.default || m)
  },
  {
    name: esqueci_45minha_45senha0Chy9e6ZHFMeta?.name ?? "esqueci-minha-senha",
    path: esqueci_45minha_45senha0Chy9e6ZHFMeta?.path ?? "/esqueci-minha-senha",
    meta: esqueci_45minha_45senha0Chy9e6ZHFMeta || {},
    alias: esqueci_45minha_45senha0Chy9e6ZHFMeta?.alias || [],
    redirect: esqueci_45minha_45senha0Chy9e6ZHFMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/esqueci-minha-senha.vue").then(m => m.default || m)
  },
  {
    name: indexLwK5aJNTtZMeta?.name ?? "index",
    path: indexLwK5aJNTtZMeta?.path ?? "/",
    meta: indexLwK5aJNTtZMeta || {},
    alias: indexLwK5aJNTtZMeta?.alias || [],
    redirect: indexLwK5aJNTtZMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login1bTCdJY3PiMeta?.name ?? "login",
    path: login1bTCdJY3PiMeta?.path ?? "/login",
    meta: login1bTCdJY3PiMeta || {},
    alias: login1bTCdJY3PiMeta?.alias || [],
    redirect: login1bTCdJY3PiMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexe8zHd2jHxiMeta?.name ?? "preferencias",
    path: indexe8zHd2jHxiMeta?.path ?? "/preferencias",
    meta: indexe8zHd2jHxiMeta || {},
    alias: indexe8zHd2jHxiMeta?.alias || [],
    redirect: indexe8zHd2jHxiMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/preferencias/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93HQGkMXoYfZMeta?.name ?? "resetar-senha-uid-token",
    path: _91token_93HQGkMXoYfZMeta?.path ?? "/resetar-senha/:uid()/:token()",
    meta: _91token_93HQGkMXoYfZMeta || {},
    alias: _91token_93HQGkMXoYfZMeta?.alias || [],
    redirect: _91token_93HQGkMXoYfZMeta?.redirect,
    component: () => import("/webapps/qa_md1sender/frontend/pages/resetar-senha/[uid]/[token].vue").then(m => m.default || m)
  }
]