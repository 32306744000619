import { useAuthStore } from '~/stores/authStore';
import { useRuntimeConfig, type UseFetchOptions } from '#app';

export const useFetchDjango = (url:string,options: UseFetchOptions<object> = {}, auth:boolean = true) => {
    const nuxtApp = 
    options.baseURL = useRuntimeConfig().public.djangoHost

    if (auth === true) {
      options.headers = {
        'Authorization': `Token ${useAuthStore().token}`
      }
    }

    return useFetch(url, {
      ...options,
      async onRequestError({ request, options, error }) {
        console.log('Error:', error)
      },
      async onResponseError({ response, options, error }) {
        if (response && response.status === 401) {
          useToast().add({ 
            title: 'Sessão expirada!',
            description: 'Por favor, realize o login novamente.', 
            color: 'red',
            icon:'i-heroicons-information-circle',
            timeout:5000,
            ui:{
              ring: 'ring-0 ring-red-500 dark:ring-gray-800',
              background:'bg-red-50',
              title: 'text-md font-medium text-gray-900 dark:text-white',
              rounded: 'rounded',
              default:{
                closeButton:{
                    color: 'red',
                }
              }
            }
          })

          useAuthStore().reset()
          navigateTo('/login')
        }
      }
      
    })  
}