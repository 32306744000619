import revive_payload_client_4sVQNw7RlN from "/webapps/qa_md1sender/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/webapps/qa_md1sender/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/webapps/qa_md1sender/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/webapps/qa_md1sender/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/webapps/qa_md1sender/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/webapps/qa_md1sender/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/webapps/qa_md1sender/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/webapps/qa_md1sender/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/webapps/qa_md1sender/frontend/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import slideovers_LDumGYo2KH from "/webapps/qa_md1sender/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/webapps/qa_md1sender/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/webapps/qa_md1sender/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_OVoKJro5pc from "/webapps/qa_md1sender/frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_UciE0i6zes from "/webapps/qa_md1sender/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/webapps/qa_md1sender/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import api_GFfDXud5Cr from "/webapps/qa_md1sender/frontend/plugins/api.ts";
import websocket_client_yuEk7YXzOs from "/webapps/qa_md1sender/frontend/plugins/websocket.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_OVoKJro5pc,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  api_GFfDXud5Cr,
  websocket_client_yuEk7YXzOs
]