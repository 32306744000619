import { defineStore } from 'pinia';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => {
    return {
      isLoggedIn: false,
      first_login: false,
      logando: false,
      expirado: false,
      email: '',
      user: null,
      token: '',
      armazenamento_usado: 0,
      armazenamento_limite: 0,
      status_armazenamento: 'success',
      errors: [],
      dismissWarnings: {
        apagarConversas: false
      }
    }
  },
  getters: {
    isAuthenticated() {
      return this.isLoggedIn;
    },

    armazenamentoUsado() {
      return this.armazenamento_usado
    },

    isExpirado() {
      return this.expirado
    },
    currentUser(state) {
      return state.user;
    },
  },
  actions: {
    setToken(token) {
      this.token = token;
      this.isLoggedIn = true;
    },

    setUser(user) {
      this.user = user.id;
      this.email = user.email;
      this.first_login = user.primeiro_login;
    },

    setArmazenamentoUsado(perc) {
      this.armazenamento_usado = perc;
    },

    setStatusArmazenamento(status) {
      this.status_armazenamento = status;
    },

    setArmazenamentoLimite(limite) {
      this.armazenamento_limite = limite;
    },

    logout(state) {
      // Implement logout logic here, e.g., removing user data
      const {$api} = useNuxtApp()
      const {$disconnectWebSocket} = useNuxtApp()
      $api('/api/token/logout/', { 'method': 'POST' })
        .then(response => {
          this.isLoggedIn = false;
          this.first_login = false,
            this.logando = false
          this.user = null;
          this.token = '';
          useWppDisparoStore().reset()
          $disconnectWebSocket()
          navigateTo('/login')
        })
      return true
    },

    setExpirado(valor) {
      this.expirado = valor;
    },

    alterarSenha(formData) {
      this.errors = null;
      useFetchDjango('/api/users/set_password/', 'POST', formData)
        .then(response => {
          console.log(response);
        }).catch(error => {
          this.errors = error;
        })
      return true;
    },

    sessaoExpirada() {
      this.expirado = true
      this.user = null
      this.isLoggedIn = false
      this.logando = false
      this.token = ''
      navigateTo('/login')
    },

    reset() {
      this.$reset()
    }
  },
  persist: true,
});
